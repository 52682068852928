import { NetOfferService } from './../../services/net-offer-service';
import { ReportService } from './../../services/report-service';
import { ImpregnationService } from './../../services/impregnation-service';
import { DialogController, DialogService } from 'aurelia-dialog';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { autoinject, bindable, LogManager } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { Models } from 'models/core';
import { CuttingStrapTypeService } from 'services/cutting-strap-type-service';
import { ErrorService } from 'services/error-service';
import { NetPreparationService } from 'services/net-preparation-service';
import { NetService } from 'services/net-service';
import { PackagingMethodService } from 'services/packaging-method-service';
import { PackagingTypeService } from 'services/packaging-type-service';
import { PreparationRopeService } from 'services/preparation-rope-service';
import { StrapTypeService } from 'services/strap-type-service';
import { ImpregnationTypeService } from 'services/impregnation-type-service';
import { ToastService } from 'services/toast-service';
import { I18N } from 'aurelia-i18n';
import { Utility } from '../../utility';
import { ValidationController, validateTrigger, ValidationRules } from 'aurelia-validation';
import { Prompt } from 'elements/prompt';
import { Logger } from 'aurelia-logging';

const logger: Logger = LogManager.getLogger('simple-offer-tab-impregnation');

@autoinject
export class SimpleOfferTabImpregnation {
  private netPreparationId: number;
  private impregnationId: number;
  private netId: number;
  private originalObject;
  private originalImpregnationObject;

  private net: Models.Net;
  private formIsValid: boolean = true;
  private locked: boolean = false;

  private impregnation: Models.Impregnation;

  private netpreparation: Models.NetPreparation;
  private preparationropes: Array<Models.PreparationRope>;
  private nextTabIndex: number = null;
  private packagingmethods: Array<Models.PackagingMethod>;
  private preparationRopeNewFormVisible: boolean = false;
  private preparationRopeEditFormVisible: number = null;

  public noPreparationDataExists: boolean = false;

  private downloadOfferSpecificationProgress: boolean = false;
  private downloadOfferConceptDrawingProgress: boolean = false;

  private changeTabSubscription: Subscription;
  private subscription1: Subscription;
  private subscription2: Subscription;
  private subscription3: Subscription;

  constructor(
    private cuttingStrapTypeService: CuttingStrapTypeService,
    private dialogService: DialogService,
    private errorService: ErrorService,
    private eventAggregator: EventAggregator,
    private reportService: ReportService,
    private i18n: I18N,
    private impregnationTypeService: ImpregnationTypeService,
    private impregnationService: ImpregnationService,
    private netPreparationService: NetPreparationService,
    private netService: NetService,
    private packagingMethodService: PackagingMethodService,
    private packagingTypeService: PackagingTypeService,
    private preparationRopeService: PreparationRopeService,
    private router: Router,
    private strapTypeService: StrapTypeService,
    private toastService: ToastService,
    private utility: Utility,
    private validationController: ValidationController
  ) {}
  private activate(params) {
    this.netId = params.NetId || params.Id;
    this.getNet(this.netId);
    this.getPackagingMethods();

    // Update NetPreparation when creating new preparationRopes
    this.subscription1 = this.eventAggregator.subscribe('preparationRopeListReset', (value) => {
      this.preparationRopeEditFormVisible = null;
      this.getPreparationRopes();
      this.eventAggregator.publish('offer-refresh', 'price');
    });

    this.subscription2 = this.eventAggregator.subscribe('preparationRopeFormEditClose', (value) => {
      this.getPreparationRopes();
    });

    this.eventAggregator.subscribe('preparationRopeFormNewClose', (value) => {
      this.preparationRopeNewFormVisible = false;
    });

    // Get new tabIndex for tabs component via EA, store value so we can publish this if canDeactivate returns true
    this.changeTabSubscription = this.eventAggregator.subscribe('changeTab', (tabIndex) => {
      this.nextTabIndex = tabIndex;
    });

    // refresh if locked / unlocked
    this.subscription3 = this.eventAggregator.subscribe('netLocked', (value) => {
      this.getNet(this.netId);
    });
  }

  private detached() {
    if (this.changeTabSubscription) {
      this.changeTabSubscription.dispose();
    }
  }

  private deactivate() {
    if (this.changeTabSubscription) {
      this.changeTabSubscription.dispose();
    }

    if (this.subscription1) {
      this.subscription1.dispose();
    }
    if (this.subscription2) {
      this.subscription2.dispose();
    }
    if (this.subscription3) {
      this.subscription3.dispose();
    }
  }

  private deletePreparationRope(id) {
    this.preparationRopeService
      .delete(id)
      .then((res) => {
        this.getPreparationRopes();
        this.toastService.showSuccess('preparationrope.deleted');
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private updateNetPreparation() {
    this.netpreparation.PreparationRopes = null;
    this.netPreparationService
      .put(this.netpreparation, this.netpreparation.Id)
      .then((res) => {
        this.toastService.showSuccess('netpreparation.updated');
        this.getNetPreparation(this.netPreparationId);
        this.updateOriginalObject();
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private cancel() {
    this.netpreparation = this.originalObject;
  }

  private getNet(id) {
    this.netService
      .get(id)
      .then((res) => {
        this.net = res;
        this.netPreparationId = res.NetPreparationId;
        this.impregnationId = res.ImpregnationId;
        this.locked = res.Locked ? res.Locked : false;

        if (this.net.NetPreparationId) {
          this.noPreparationDataExists = false;
          this.getNetPreparation(this.netPreparationId);
        } else {
          this.noPreparationDataExists = true;
        }

        if (this.net.ImpregnationId) {
          this.getImpregnation(this.impregnationId);
        }
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private getNetPreparation(id) {
    this.netPreparationService
      .get(id)
      .then((res) => {
        this.netpreparation = res;
        this.updateOriginalObject();
        this.getPreparationRopes();
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private getImpregnation(id) {
    this.impregnationService
      .get(id)
      .then((res) => {
        this.impregnation = res;
        this.updateOriginalImpregnationObject();
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private getPackagingMethods() {
    this.packagingMethodService
      .getAllCached()
      .then((res) => {
        this.packagingmethods = res.filter((x) => !x.IsDeleted);
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private getPreparationRopes() {
    this.preparationRopeService
      .getAll(
        '?$filter=NetPreparationId eq ' +
          this.netpreparation.Id +
          '&$expand=RopeType,PreparationRopeType,RopeDimension,PreparationRopePlacement,PreparationRopeKnot1,PreparationRopeKnot2,PreparationRopeKnotPlacement1,PreparationRopeKnotPlacement2'
      )
      .then((res) => {
        this.preparationropes = res;
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private addValidationRules() {
    /*     ValidationRules.ensure('NetTypeId')
      .required()
      .withMessage(this.i18n.tr('general.requiredField'))
      .on(this.net); */
  }

  private validateForm() {
    this.validationController.validate().then((result) => {
      if (result.valid) {
        this.formIsValid = true;
      } else {
        this.formIsValid = false;
      }
    });
  }

  private async validateAsync(): Promise<any> {
    return new Promise<void>(async (resolve, reject) => {
      this.validationController
        .validate()
        .then((result) => {
          if (result.valid) {
            this.formIsValid = true;
            resolve();
          } else {
            this.formIsValid = false;
            reject();
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  private updateOriginalObject() {
    if (!this.netpreparation) {
      return;
    }
    this.originalObject = JSON.parse(JSON.stringify(this.netpreparation));
  }

  private updateOriginalImpregnationObject() {
    if (!this.impregnation) {
      return;
    }
    this.originalImpregnationObject = JSON.parse(JSON.stringify(this.impregnation));
  }

  private async save(): Promise<any> {
    return new Promise<void>(async (resolve, reject) => {
      this.netpreparation.PreparationRopes = null;
      Promise.all([
        this.netPreparationService.put(this.netpreparation, this.netpreparation.Id),
        this.impregnationService.put(this.impregnation, this.impregnationId),
      ])
        .then((res) => {
          this.toastService.showSuccess('netpreparation.updated');
          this.getNetPreparation(this.netPreparationId);
          this.getImpregnation(this.impregnationId);
          this.updateOriginalObject();
          this.updateOriginalImpregnationObject();

          resolve();
        })
        .catch((err) => {
          this.errorService.handleError(err);
          reject(err);
        });
    });
  }

  private changeTab(direction: string = 'next') {
    if (direction === 'prev') {
      this.eventAggregator.publish('triggerPrevTab', true);
    } else {
      this.eventAggregator.publish('triggerNextTab', true);
    }
  }

  private saveAndChangeTab(direction: string = 'next') {
    // does it validate ?
    this.validateAsync()
      .then(() => {
        // check if different
        if (
          !this.utility.areEqual(this.impregnation, this.originalImpregnationObject, true) ||
          !this.utility.areEqual(this.netpreparation, this.originalObject, true)
        ) {
          // save
          this.save().then((res) => {
            this.changeTab(direction);
          });
        } else {
          this.changeTab(direction);
        }
      })
      .catch((error) => {
        logger.debug('error', error);
        this.toastService.showWarning('general.checkValidationErrors');
      });
  }

  private saveAndGotoNextTab() {
    // does it validate ?
    this.validateAsync()
      .then(() => {
        // check if different
        if (!this.utility.areEqual(this.netpreparation, this.originalObject, true)) {
          // save
          this.save().then((res) => {
            this.eventAggregator.publish('triggerNextTab', true);
          });
        } else {
          this.eventAggregator.publish('triggerNextTab', true);
        }
      })
      .catch((error) => {
        this.toastService.showWarning('general.checkValidationErrors');
      });
  }

  private canDeactivate() {
    if (
      !this.net.Locked &&
      ((this.originalObject && !this.utility.areEqual(this.netpreparation, this.originalObject)) ||
        this.preparationRopeNewFormVisible ||
        this.preparationRopeEditFormVisible > 0)
    ) {
      if (this.originalObject && !this.utility.areEqual(this.netpreparation, this.originalObject)) {
        // tslint:disable-next-line:no-console
        return this.dialogService
          .open({
            viewModel: Prompt,
            model: { message: 'dialog.unsavedChangesText' },
          })
          .whenClosed((response) => {
            if (response.wasCancelled) {
              return false;
            } else {
              const result = response.output;
              if (result === 'save') {
                // save the nettingtype and let that function handle the rest of the logic
                this.updateNetPreparation();
                return false;
              } else {
                return true;
              }
            }
          });
      }
      if (this.preparationRopeNewFormVisible || this.preparationRopeEditFormVisible > 0) {
        return this.dialogService
          .open({
            viewModel: Prompt,
            model: {
              header: 'dialog.subFormOpenHeading',
              message: 'dialog.subFormOpenMessage',
              actions: {
                delete: { enabled: false },
                save: { enabled: false },
                cancel: { enabled: true, t: 'dialog.cancel' },
                dontsave: { enabled: false },
                continue: { enabled: true, t: 'dialog.continue' },
              },
            },
          })
          .whenClosed((response) => {
            if (response.wasCancelled) {
              return false;
            } else {
              const result = response.output;
              if (result === 'continue') {
                this.eventAggregator.publish('changeTab-success', this.nextTabIndex);
                return true;
              }
            }
          });
      }
    } else {
      this.eventAggregator.publish('changeTab-success', this.nextTabIndex);
      return true;
    }
  }

  private downloadOfferSpecification() {
    if (this.downloadOfferSpecificationProgress) {
      return;
    }

    this.downloadOfferSpecificationProgress = true;
    this.reportService
      .getSpecificationReport(this.netId)
      .then((res) => {
        this.toastService.showSuccess('document.downloaded');
        this.downloadOfferSpecificationProgress = false;
      })
      .catch((err) => {
        this.errorService.handleError(err);
        this.downloadOfferSpecificationProgress = false;
      });
  }

  private downloadOfferConceptDrawing() {
    if (this.downloadOfferConceptDrawingProgress) {
      return;
    }

    this.downloadOfferConceptDrawingProgress = true;
    this.reportService
      .getDrawingReport(this.netId)
      .then((res) => {
        this.toastService.showSuccess('document.downloaded');
        this.downloadOfferConceptDrawingProgress = false;
      })
      .catch((err) => {
        this.errorService.handleError(err);
        this.downloadOfferConceptDrawingProgress = false;
      });
  }
}
